import React from "react";
import Prism from 'prismjs';
import { Highlight, themes } from "prism-react-renderer";
import 'prismjs/components/prism-markup-templating';
require("prismjs/components/prism-bash");
require("prismjs/components/prism-php");

const Code = ({ codeString, language, ...props }) => {
  return (
    <Highlight 
      prism={Prism}
      theme={themes.vsDark}
      language={language}
      code={codeString}
    >
      {({ className, style, tokens, getLineProps, getTokenProps }) => (
        <pre {...props} className={`language-${language}`} style={{...style, padding: '1rem', overflow: 'auto'}}>
          {tokens.map((line, i) => (
            <div key={i} {...getLineProps({ line })}>
              {/* <span>{i + 1}&nbsp;</span> */}
              {line.map((token, key) => (
                <span key={key} {...getTokenProps({ token })} />
              ))}
            </div>
          ))}
        </pre>
      )}
    </Highlight>
  )
}

export default Code;
import "typeface-open-sans";
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/a11y';
import 'swiper/css/autoplay';
import './src/styles/main.css';
import ReactDOM from "react-dom/client";
import { wrapRootElement as wrap } from './wrap-root-element'
export const wrapRootElement = wrap

export const replaceHydrateFunction = () => {
  return (element, container) => {
    const root = ReactDOM.createRoot(container);
    root.render(element);
  }
}
import React from 'react'
import { MDXProvider } from '@mdx-js/react'
import slug from 'slug';
import { preToCodeBlock } from './src/utils/pre-code-to-block.js'
import Code from './src/utils/code.js'

const components = {
  h2: props => {
    return <h2 id={slug(props.children)} {...props} />
  },
  h3: props => {
    return <h3 id={slug(props.children)} {...props} />
  },
  pre: preProps => {
    const props = preToCodeBlock(preProps)
    if (props) { return <Code {...props} /> }
    return <pre {...preProps} />
  },
  wrapper: ({ children }) => <>{children}</>,
}
export const wrapRootElement = ({ element }) => (
  <MDXProvider components={components}>
    {element}
  </MDXProvider>
);
